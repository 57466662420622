import { CalloutSection } from "@ecologi/react-components/src/CalloutSection";
import { Testimonial } from "@ecologi/react-components/src/Testimonial/Testimonial";
import { EcologiColour } from "@ecologi/react-components/src/theme/ecologi-colors";
import { ReactNode } from "react";

export default (props: any) => {
  return (
    <CalloutSection
      background={{ image: props.data.background_image_desktop }}
      {...((props.data.button_link ||
        props.data.button_text ||
        props.data.button_variant) && {
        button: {
          href: props.data.button_link,
          text: props.data.button_text,
          ...(props.data.button_variant && {
            variant: props.data.button_variant,
          }),
        },
      })}
      content={props.data.text}
      {...(props.data.slot_content && {
        slot: slotContentMapper[props.data.slot_content],
      })}
      title={props.data.title}
    />
  );
};

const slotContentMapper: Record<string, ReactNode> = {
  mike_berners_lee: (
    <Testimonial
      image={{
        borderColour: EcologiColour.snowdrop,
        size: 250,
        url: "https://cms-assets.offset.earth/app/uploads/20230213152241/mike_berners_lee.jpg",
      }}
      testimony="We are delighted that Ecologi are early adopters of our new emissions dataset, which we have worked to ensure is as up to date, robust, and realistic as possible"
      testimonySource="Leading carbon footprinting expert Prof. Mike Berners-Lee."
      textColour={EcologiColour.snowdrop}
    />
  ),
};
