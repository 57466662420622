import { isString } from "@ecologi/shared";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ReactNode, useCallback } from "react";
import { Button, ButtonVariants } from "../Button";
import { EcologiColour } from "../theme/ecologi-colors";
import { Typography } from "../Typography";
import { Wrapper } from "../Wrapper";

export type CalloutSectionProps = {
  background?:
    | {
        colour: EcologiColour;
      }
    | { image: string };
  button?: { href: string; text: string; variant?: keyof ButtonVariants };
  content: string | ReactNode;
  slot?: ReactNode;
  title: string;
};

type BackgroundProps = NonNullable<CalloutSectionProps["background"]>;

export const CalloutSection = ({
  background,
  button,
  content,
  slot,
  title,
}: CalloutSectionProps) => {
  const theme = useTheme();

  const GAP_SIZE = "60px";
  const SHARED_PADDING = css`
    padding: 36px;

    @media ${theme.bp.extraSmallUp} {
      padding: 56px;
    }
  `;

  const backgroundProps = background ?? { colour: EcologiColour.transparent };

  const getBackground = useCallback(
    (props: BackgroundProps) => css`
      ${"colour" in props
        ? `background-color: ${props.colour}`
        : `background-image: url(${props.image})`};

      background-size: cover;
      background-position: center;
    `,
    [background]
  );

  const CalloutContainer = styled.div`
    background: ${({ theme }) => theme.colors.snowdrop};
    margin: 0;
    width: 100%;
    flex-shrink: 0;
    order: 2;
    text-align: center;

    ${SHARED_PADDING}

    @media ${({ theme }) => theme.bp.tabletUp} {
      text-align: left;
    }

    @media ${({ theme }) => theme.bp.desktopUp} {
      width: 560px;
      order: unset;
    }
  `;

  const ContentWrapper = styled(Wrapper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: ${GAP_SIZE};

    @media ${({ theme }) => theme.bp.tabletUp} {
      padding: ${GAP_SIZE} 10%;
    }

    @media ${({ theme }) => theme.bp.desktopUp} {
      flex-direction: row;
      padding-left: 0;
      padding-right: 50px;
    }
  `;

  const ParentContainer = styled.div<BackgroundProps>`
    @media ${({ theme }) => theme.bp.tabletUp} {
      ${(props) => getBackground(props)};
    }
  `;

  const SlotContainer = styled.div<BackgroundProps>`
    min-height: 200px;

    @media ${({ theme }) => theme.bp.desktopUp} {
      min-height: unset;
    }

    @media ${({ theme }) => theme.bp.tabletDown} {
      width: 100%;
      ${(props) => getBackground(props)}
      ${SHARED_PADDING}
    }
  `;

  const StyledButton = styled(Button)`
    margin-top: 32px;
    margin-bottom: 24px;

    @media ${({ theme }) => theme.bp.tabletUp} {
      margin-bottom: 0;
    }
  `;

  const Text = styled.p`
    font-size: 14px;
    line-height: 160%;

    @media ${(props) => props.theme.bp.extraSmallUp} {
      font-size: 16px;
    }
  `;

  const Title = styled(Typography)`
    margin-bottom: 20px;
  `;

  return (
    <ParentContainer
      {...backgroundProps}
      data-testid="calloutsection-container"
    >
      <ContentWrapper isExtraLarge noPadding>
        <CalloutContainer>
          <Title use="sectionTitle">{title}</Title>
          {isString(content) ? <Text>{content}</Text> : content}
          {button && (
            <StyledButton
              extra={{ "data-testid": "calloutsection-button" }}
              href={button.href}
              text={button.text}
              {...(button.variant && { [button.variant]: true })}
            />
          )}
        </CalloutContainer>
        <SlotContainer {...backgroundProps}>{slot}</SlotContainer>
      </ContentWrapper>
    </ParentContainer>
  );
};
